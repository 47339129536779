import styled from 'styled-components';
import { Container } from '@/components';
import { WrapperBackgroundColor } from '@/interfaces';
import Typography from '../Typography';
import { TextStyles } from '@/constants';

const clampText = `
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  white-space: normal;
`;

export const SectionContainer = styled(Container)<{ containsDataPicker: boolean }>`
  ${({ theme }) => `
    padding: ${theme.spacing[40]} ${theme.spacing[20]};

    ${theme.mediaBreakpointUp.md} {
      padding: ${theme.spacing[64]} ${theme.spacing[20]};
    }
  `}

  ${({ theme, containsDataPicker }) =>
    containsDataPicker &&
    `
    padding: ${theme.spacing[20]};

    ${theme.mediaBreakpointUp.md} {
      padding: ${theme.spacing[40]} ${theme.spacing[20]};
    }
  `}
`;

export const WrapperTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  margin: 0 auto;
  margin-bottom: 1px;
  max-width: 736px;
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  ${clampText}
  text-align: center;
`;

export const WrapperTitleUnderline = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})`
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};

  ::after {
    content: '';
    display: block;
    width: 40px;
    height: 3px;
    margin: 0 auto;
    margin-top: 1px;
    background: ${({ theme }) => theme.colors.darkOrange};
  }
`;

export const WrapperSubText = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  margin: 0 auto;
  max-width: 736px;
  margin-bottom: ${({ theme }) => theme.spacing[32]};
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  ${clampText}
  -webkit-line-clamp: 4;
  text-align: center;
`;

export const WrapperSectionContainer = styled.div<{
  bgColor: WrapperBackgroundColor;
  isFullWidthContent?: boolean;
}>`
  ${({ theme, bgColor, isFullWidthContent }) => `
      background: ${
        bgColor === WrapperBackgroundColor.grey ? theme.colors.pageBackground : theme.colors.highEmphasisWhite
      };
    border-top: 4px solid ${theme.colors.surface05};

    ${
      isFullWidthContent
        ? `
      padding-bottom: ${theme.spacing[40]};

      ${SectionContainer} {
        padding-bottom: 0;
      }
   `
        : ''
    }

    ${theme.mediaBreakpointUp.md} {
      border-top: none;

     ${
       isFullWidthContent
         ? `
        padding-bottom: ${theme.spacing[64]};

        ${SectionContainer} {
          padding-bottom: 0;
        }
     `
         : ''
     }
    }
  `}
`;
