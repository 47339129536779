import { ContentTypeName, WrapperEntryMapping } from '@/constants';
import type { IWrapper, WrapperEntryName } from '@/interfaces';
import { capitalize } from '@/utils';
import RichText from '../../RichText';
import WithImage from '../WithImage';
import DataPickerSection from '../DataPickerSection';

interface InnerChildProps {
  entryList: IWrapper['entryList'];
}

const InnerChild: React.FC<InnerChildProps> = ({ entryList }) => {
  const contentTypeName = entryList?.[0]?.__typename;
  const ChildOfWrapper = WrapperEntryMapping[capitalize(contentTypeName) as WrapperEntryName];
  const entryListFiltered = entryList?.filter((entry) => entry.__typename === contentTypeName);

  if (!entryListFiltered?.length || !ChildOfWrapper) return null;

  if (contentTypeName === ContentTypeName.ThumbnailSection) {
    return <ChildOfWrapper {...entryListFiltered[0]} />;
  }

  return <ChildOfWrapper data={entryListFiltered} />;
};

interface BodyComponentProps {
  data: IWrapper['data'];
  image: IWrapper['image'];
  content: IWrapper['content'];
  entryList: IWrapper['entryList'];
  dataPicker: IWrapper['dataPicker'];
}

const BodyComponent: React.FC<BodyComponentProps> = ({ data, image, content, entryList, dataPicker }) => {
  if (entryList?.length !== 0) {
    return <InnerChild entryList={entryList} />;
  }

  if (content) {
    return (
      <WithImage image={image}>
        <RichText richText={content} />
      </WithImage>
    );
  }

  return <DataPickerSection data={data} dataPicker={dataPicker} />;
};

export default BodyComponent;
