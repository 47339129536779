import { type IWrapper, ResponsiveCollection, WrapperEntryName } from '@/interfaces';
import { capitalize } from '@/utils';
import { useResponsive } from '@/hooks';
import { BodyComponent, FullWidthComponent } from './WrapperTemplates';
import {
  WrapperSectionContainer,
  WrapperTitleUnderline,
  SectionContainer,
  WrapperSubText,
  WrapperTitle,
} from './Wrapper.styles';

const Wrapper: React.FC<IWrapper> = ({
  title,
  subText,
  backgroundColor,
  subTextMobile,
  entryList,
  content,
  image,
  dataPicker,
  data,
}) => {
  const FULL_WIDTH_ENTRY_NAME = WrapperEntryName.Company; // This is the only case where we want to render the content as full width

  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);
  const isFullWidthContent = capitalize(entryList?.[0]?.__typename) === FULL_WIDTH_ENTRY_NAME;

  const shouldRenderWrapper = !!(isFullWidthContent || entryList?.length || content || data.length);

  if (!shouldRenderWrapper) return null;

  const containsDataPicker = !!(!content && !entryList?.length && dataPicker);

  return (
    <WrapperSectionContainer bgColor={backgroundColor} isFullWidthContent={isFullWidthContent}>
      <SectionContainer containsDataPicker={containsDataPicker}>
        {title && (
          <>
            <WrapperTitle>{title}</WrapperTitle>
            <WrapperTitleUnderline />
          </>
        )}
        {isMobile && subTextMobile && <WrapperSubText>{subTextMobile}</WrapperSubText>}
        {!isMobile && subText && <WrapperSubText>{subText}</WrapperSubText>}
        {!isFullWidthContent && (
          <BodyComponent data={data} image={image} content={content} entryList={entryList} dataPicker={dataPicker} />
        )}
      </SectionContainer>

      {isFullWidthContent && <FullWidthComponent image={image} entryList={entryList} />}
    </WrapperSectionContainer>
  );
};

export default Wrapper;
