import { type IWrapper, WrapperEntryName } from '@/interfaces';
import { WrapperEntryMapping } from '@/constants';

interface FullWidthComponentProps {
  entryList: IWrapper['entryList'];
  image: IWrapper['image'];
}

const FullWidthComponent: React.FC<FullWidthComponentProps> = ({ image, entryList }) => {
  const FULL_WIDTH_ENTRY_NAME = WrapperEntryName.Company; // This is the only case where we want to render the content as full width

  const ChildOfWrapper = WrapperEntryMapping[FULL_WIDTH_ENTRY_NAME];
  const entryListFiltered = entryList?.filter((entry) => entry.__typename === FULL_WIDTH_ENTRY_NAME);

  return <ChildOfWrapper image={image} data={entryListFiltered} />;
};

export default FullWidthComponent;
