import { BulletPontContainer } from '@/components/ContentItem/Templates/BulletPoints/BulletPoints.styles';
import styled from 'styled-components';

export const WithImageWrapper = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: flex;
    justify-content: center;
  }
`;

export const RightPanel = styled.div<{ hasMetric?: boolean }>`
  flex: 66%;
  
  ${BulletPontContainer}:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[24]};
  }

  ${({ hasMetric, theme }) =>
    hasMetric &&
    `
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;

    ${theme.mediaBreakpointUp.md} {
      max-width: 736px;
      margin: 0 auto;
    }
  `} 
`;

export const LeftPanel = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[32]};
  flex: 33%;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacing[32]};
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 240px;
  position: relative;
  display: block;
`;
