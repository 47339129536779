import { DataPickerContentType, WrapperDataPickerMapping } from '@/constants';
import { type IDataPickerSection, DataPickerUiTemplate, type ITableSectionCustomMapper } from '@/interfaces';

const MINIMUM_RETAILERS_IN_TABLE_SECTION = 15; // must larger than 5
const MAX_PAGE_COUNT_OF_RETAILERS_IN_TABLE_SECTION = 5;

const customPropsMapper: {
  [key in DataPickerContentType]?: ITableSectionCustomMapper;
} = {
  [DataPickerContentType.retailer]: {
    minimumRecord: MINIMUM_RETAILERS_IN_TABLE_SECTION,
    pseudoPageCount: MAX_PAGE_COUNT_OF_RETAILERS_IN_TABLE_SECTION,
  },
};

const DataPickerSection: React.FC<IDataPickerSection> = ({ dataPicker, data }) => {
  const { uiTemplate, contentType } = dataPicker;
  const Component = WrapperDataPickerMapping[dataPicker.uiTemplate];

  if (uiTemplate === DataPickerUiTemplate.Columns) {
    return <Component data={data} dataPicker={dataPicker} />;
  }

  const customProps = customPropsMapper[contentType];

  return <Component data={data} dataPicker={dataPicker} customProps={customProps} />;
};

export default DataPickerSection;
