import styled from 'styled-components';
import { Typography, Button } from '@/components';
import { TextStyles } from '@/constants';
import { ButtonVariant } from '@/interfaces';

export const BulletPontContainer = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  padding-top: 2px;
`;

export const ContentWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing[16]};
`;

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 3'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const SubTitle = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  margin-bottom: ${({ theme }) => theme.spacing[12]};
`;

export const HyperLink = styled(Button).attrs({ variant: ButtonVariant.Link })``;
