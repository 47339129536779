import Image from 'next/image';
import { useGlobalState } from '@/hooks';
import { WithImageWrapper, ImageWrapper, RightPanel, LeftPanel } from './WithImage.styles';

interface WithImageProps {
  hasMetric?: boolean;
  image?: {
    contentType?: string;
    url: string;
    description: string;
  };
}

const WithImage: React.FC<WithImageProps> = ({ image, hasMetric, children }) => {
  const { getProperImageUrl } = useGlobalState();

  return (
    <WithImageWrapper>
      {image && (
        <LeftPanel>
          <ImageWrapper>
            <Image
              src={getProperImageUrl(image)}
              alt={image.description}
              quality={100}
              fill
              sizes='100vw'
              style={{
                objectFit: 'contain',
                objectPosition: 'top',
              }}
            />
          </ImageWrapper>
        </LeftPanel>
      )}
      <RightPanel hasMetric={hasMetric}>{children}</RightPanel>
    </WithImageWrapper>
  );
};

export default WithImage;
